import React, { useState } from "react";
import Layout from "../Components/Layout";
import DynamicTable from "../Components/Tables/DynamicTable";
import { fetchMappedAccounts } from "../Services/Api";
import useFetch from "../hooks/useFetch";
import { CSVLink } from "react-csv";

function MappedAccount() {
  const { data: mappedAccounts, loading, error } = useFetch(fetchMappedAccounts);
  const [searchQuery, setSearchQuery] = useState("");
  const csvData = mappedAccounts || [];

  // Filter function for search
  const filteredData = React.useMemo(() => {
    if (loading) return [];
    if (!mappedAccounts) return [];
    if (!searchQuery.trim()) return mappedAccounts;

    return mappedAccounts.filter(account => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        account.artistName.toLowerCase().includes(searchTerm) ||
        account.marketplaceArtistName.toLowerCase().includes(searchTerm)
      );
    });
  }, [mappedAccounts, searchQuery, loading]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="flex items-center gap-3">
          <img
            src="./assests/Rectangle 5912.png"
            alt="ID Icon"
            className="w-4 h-4 mr-2 ml-2"
          />
          <span className="ml-10">{value}</span>
        </div>
      ),
    },
    {
      Header: "Artist Name",
      accessor: "artistName",
    },
    {
      Header: "Marketplace Artist Name",
      accessor: "marketplaceArtistName",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span className={`px-2 py-1 rounded ${
          value === 'Active' ? 'bg-green-100 text-green-800' : 
          value === 'Inactive' ? 'bg-red-100 text-red-800' : 
          'bg-gray-100 text-gray-800'
        }`}>
          {value}
        </span>
      ),
    },
    {
      Header: "Approved Date",
      accessor: "approvedDate",
    },
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Layout>
      <div className="bg-customBlue overflow-hidden w-full max-w-[1200px] h-[850px] mt-8 p-6 flex flex-col space-y-6 mr-20 ml-14 rounded-lg shadow-lg">
        <div className="flex items-center gap-20">
          <h1
            className="text-2xl font-semibold text-white"
            style={{ fontFamily: "Poppins" }}
          >
            Mapped Accounts
          </h1>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search by artist name..."
                className="p-2 pl-10 rounded-md border bg-customColor w-[260.75px] h-[46px] text-white"
                style={{ borderRadius: "4px", border: "0.6px solid #343B4F" }}
              />
              <img
                src="./assests/Search.png"
                alt="Search Icon"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
            </div>
          </div>
          <div className="flex space-x-1" style={{ position: "relative", left: "440px" }}>
            <CSVLink
              data={csvData}
              headers={columns.map((col) => ({
                label: col.Header,
                key: col.accessor,
              }))}
              filename={"mapped-accounts.csv"}
              className="text-white p-2 w-[100px] h-[43.88px] rounded-sm hover:transform transition-transform duration-200 hover:scale-105"
              style={{
                border: "1px solid #ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex flex-row gap-3">
                <span
                  className="font-poppins"
                  style={{ fontWeight: "500", lineHeight: "12.78px" }}
                >
                  Export
                </span>
                <img
                  className="transform rotate-90 w-[11.36px] h-[11.36px]"
                  src="./assests/Arrow Right.png"
                  alt="pic"
                />
              </div>
            </CSVLink>
          </div>
        </div>
        <div className="overflow-auto">
          <DynamicTable columns={columns} data={filteredData} loading={loading} error={error} />
        </div>
      </div>
    </Layout>
  );
}

export default MappedAccount; 