import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReviewFeature from "./ReviewFeature";
import Dropdown from "./Dropdown";
import Layout from "../../Components/Layout";
import AddArtistModal from "./AddArtistModal";
import EditArtistModal from "./EditArtistModal";
import AddCustomModal from "./AddCustomModal";

import {
  fetchReviewArtist,
  searchArtist,
  deleteArtist as apiDeleteArtist,
  getSingleArtist,
} from "../../Services/Api";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";

const ReviewDataTable = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [artists, setArtists] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [artistToDelete, setArtistToDelete] = useState(null);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [preFilledData, setPreFilledData] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [customModalIsOpen, setCustomModalIsOpen] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const savedPageSize = localStorage.getItem("pageSize");
  const defaultPageSize = 6;
  const [pageSize, setPageSize] = useState(savedPageSize ? parseInt(savedPageSize) : defaultPageSize);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        setIsLoading(true);
        const response = await fetchReviewArtist(
          sortBy, 
          sortOrder, 
          currentPage + 1, // API expects 1-based page number
          pageSize
        );
        setArtists(response.data);
        setFilteredItems(response.data);
        setTotalRecords(response.total);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching artists:", error);
        setIsLoading(false);
      }
    };

    fetchArtists();
  }, [refreshKey, sortBy, sortOrder, currentPage, pageSize]);

  useEffect(() => {
    if (searchTerm) {
      const normalizedSearchTerm = searchTerm.toLowerCase().replace(/\s+/g, "");
      const filtered = artists.filter((artist) => {
        const normalizedArtistName = artist.artistName
          .toLowerCase()
          .replace(/\s+/g, "");
        return normalizedArtistName.includes(normalizedSearchTerm);
      });
      setFilteredItems(filtered);
    } else {
      setFilteredItems(artists);
    }
  }, [searchTerm, artists]);

  const fetchSpotifyArtists = async (query) => {
    try {
      const artists = await searchArtist(query);
      setFilteredItems(artists);
    } catch (error) {
      console.error("Error fetching Spotify artists:", error);
      setFilteredItems([]);
    }
  };

  const openCustomModal = () => {
    setCustomModalIsOpen(true);
  };

  const closeCustomModal = () => {
    setCustomModalIsOpen(false);
  };

  const addCustomArtist = (newArtist) => {
    setArtists((prevArtists) => [...prevArtists, newArtist]);
    setRefreshKey((oldKey) => oldKey + 1);
    closeCustomModal();
  };

  const initialFormState = {
    name: "",
    photo: "",
    location: "",
    bio: "",
    genre: [],
    spotifyUrl: "",
    age: "",
    spotifyId: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  const handleSelectArtist = (artist) => {
    const { name, photo, spotifyId, genre, spotifyUrl, bio, location } = artist;
    const artistImage = artist.image || "path/to/default/image.jpg";
    const artistGenres = genre || [];
    const spotifyUrlartist = spotifyUrl || "";
    const spotifybio = bio || "";
    const artistLocation = location || "";

    const newFormData = {
      name,
      photo: artistImage,
      location: artistLocation,
      bio: spotifybio,
      genre: artistGenres,
      spotifyUrl: spotifyUrlartist,
      age: "",
      spotifyId: spotifyId,
    };

    setFormData(newFormData);
    setMode("prefilled");
    setModalIsOpen(true);
    // setDropdownVisible(false);
  };

  useEffect(() => {
    const updateStyle = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        setButtonStyle({
          marginBottom: "13px",
          position: "relative",
          left: "80%",
        });
      } else if (screenWidth >= 640 && screenWidth < 1300) {
        setButtonStyle({
          marginBottom: "13px",
          position: "relative",
          left: "10%",
        });
      } else {
        setButtonStyle({
          marginBottom: "13px",
          position: "relative",
          left: "42%",
        });
      }
    };
    updateStyle();
    window.addEventListener("resize", updateStyle);

    return () => window.removeEventListener("resize", updateStyle);
  }, []);

  const validateForm = (formData) => {
    const newErrors = {};

    if (formData && formData.name !== undefined) {
      if (!formData.name.trim()) {
        newErrors.name = "Name is required.";
        toast.error("Name is required.");
      }
    } else {
      newErrors.name = "Name is required.";
      toast.error("Name is required.");
    }

    if (formData.age !== undefined && formData.age !== "") {
      const age = parseInt(formData.age, 10);
      if (isNaN(age) || age <= 0) {
        newErrors.age = "Valid age is required.";
        toast.error("Valid age is required.");
      }
    }

    return newErrors;
  };

  const validateCustomForm = (formData) => {
    const newErrors = {};

    if (formData && formData.artistName !== undefined) {
      if (!formData.artistName.trim()) {
        newErrors.name = "Name is required.";
        toast.error("Name is required.");
      }
    } else {
      newErrors.artistName = "Name is required.";
      toast.error("Name is required.");
    }

    if (formData.age !== undefined && formData.age !== "") {
      const age = parseInt(formData.age, 10);
      if (isNaN(age) || age <= 0) {
        newErrors.age = "Valid age is required.";
        toast.error("Valid age is required.");
      }
    }

    return newErrors;
  };

  const validateEditForm = (formData) => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      toast.error("Name is required.");
    }

    if (formData.age !== undefined && formData.age !== "") {
      const age = parseInt(formData.age, 10);
      if (isNaN(age) || age <= 0) {
        newErrors.age = "Valid age is required.";
        toast.error("Valid age is required.");
      }
    }

    return newErrors;
  };

  const openAddModal = () => {
    setFormData(initialFormState);
    setMode("add");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setFormData(initialFormState);
    setModalIsOpen(false);
  };

  const handleDelete = (artist) => {
    setArtistToDelete(artist);
    setConfirmModalIsOpen(true);
  };

  const confirmDelete = async () => {
    if (artistToDelete) {
      try {
        await apiDeleteArtist(artistToDelete.artistId);
        setArtists((prevArtists) =>
          prevArtists.filter(
            (artist) => artist.artistId !== artistToDelete.artistId
          )
        );
        setConfirmModalIsOpen(false);
        setArtistToDelete(null);
      } catch (error) {
        console.error("Error deleting artist:", error);
      }
    }
  };

  const cancelDelete = () => {
    setConfirmModalIsOpen(false);
    setArtistToDelete(null);
  };

  const updateArtistList = (updatedArtist) => {
    setArtists((prevArtists) =>
      prevArtists.map((artist) =>
        artist.artistId === updatedArtist.artistId ? updatedArtist : artist
      )
    );
    setRefreshKey((oldKey) => oldKey + 1);
    closeModal();
  };

  const handleEdit = async (artist) => {
    try {
      const artistData = await getSingleArtist(artist.artistId);
      setPreFilledData(artistData);
      setMode("update");
      setModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching single artist data:", error);
    }
  };

  const handleReviewClick = (artist) => {
    navigate(`/reviews/${artist}`);
  };

  const addArtist = (newArtist) => {
    setArtists((prevArtists) => [...prevArtists, newArtist]);
    setRefreshKey((oldKey) => oldKey + 1);
    closeModal();
  };

  const handleSort = (field) => {
    const newSortOrder = sortBy === field && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(field);
    setSortOrder(newSortOrder);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(0); // Reset to first page when changing page size
  };

  return (
    <Layout>
      <div className="flex flex-col w-full h-[500] overflow-y-auto bg-customBlue mt-12 sm:w-full sm:overflow-x-auto">
        <div className="p-4 bg-customBlue text-white overflow-hidden sm:overflow-x-auto">
          <div className="flex flex-row sm:flex-row gap-12 ">
            <h1
              className="text-2xl font-semibold text-white ml-10 mb-4"
              style={{ fontFamily: "Poppins" }}
            >
              Artists
            </h1>

            <div className="flex items-center space-x-4 ">
              <div className="relative flex flex-row">
                <input
                  type="text"
                  placeholder="Search for..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  // onFocus={() => setDropdownVisible(true)}
                  className="p-2 pl-10 rounded-md border bg-customColor w-[260.75px] h-[46px] text-white mb-2"
                  style={{
                    borderRadius: "4px",
                    border: "0.6px solid  #343B4F",
                  }}
                />
              </div>
            </div>

            <button
              onClick={openCustomModal}
              className="w-[105px] h-[44px] bg-customBlue text-white mt-2 border-white rounded p-2 flex sm:mr-10  sm:overflow-hidden"
              style={{
                border: "1px solid #ffffff",
                marginBottom: "13px",
                ...buttonStyle,
              }}
            >
              <span className="font-poppins" style={{ whiteSpace: "nowrap" }}>
                + Add Artist
              </span>
            </button>
          </div>

          {dropdownVisible && searchTerm && (
            <Dropdown items={filteredItems} onSelect={handleSelectArtist} />
          )}
          <ReviewFeature
            data={filteredItems}
            onDelete={handleDelete}
            onUpdate={handleEdit}
            handleReviewClick={handleReviewClick}
            onSort={handleSort}
            totalRecords={totalRecords}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            isLoading={isLoading}
          />
          <AddCustomModal
            isOpen={customModalIsOpen}
            onRequestClose={closeCustomModal}
            onSave={addCustomArtist}
            validateForm={validateCustomForm}
          />
          {mode === "update" && (
            <EditArtistModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              onSave={(artist) => {
                updateArtistList(artist);
              }}
              preFilledUpdateData={preFilledData}
              validateForm={validateEditForm}
            />
          )}
          {mode === "prefilled" && (
            <AddArtistModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              onSave={(artist) => {
                addArtist(artist);
              }}
              preFilledData={formData}
              validateForm={validateForm}
            />
          )}
        </div>
        {confirmModalIsOpen && (
          <ConfirmationModal
            isOpen={confirmModalIsOpen}
            onRequestClose={cancelDelete}
            onConfirm={confirmDelete}
          />
        )}
        <ToastContainer />
      </div>
    </Layout>
  );
};

export default ReviewDataTable;
