import React from "react";
import Layout from "../Components/Layout";
import DynamicTable from "../Components/Tables/DynamicTable";
import { fetchUsers } from "../Services/Api";
import useFetch from "../hooks/useFetch";
import { CSVLink } from "react-csv";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    Cell: ({ value }) => (
      <div className="flex items-center gap-3">
        <img
          src="./assests/Rectangle 5912.png"
          alt="ID Icon"
          className="w-4 h-4 mr-2 ml-2"
        />
        <span className="ml-10">{value}</span>
      </div>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "User Name",
    accessor: "userName",
  },
  {
    Header: "User Type",
    accessor: "userType",
  },

  {
    Header: "Profile Photo",
    accessor: "profilePictureKey",
    Cell: ({ cell: { value } }) => (
      <div className="flex items-center flex-col justify-center ml-8 w-10 h-10 rounded-full overflow-hidden">
        {value ? (
          <img
            src={value}
            alt="Profile"
            className="w-[32px] h-[32px] rounded-full overflow-hidden"
          />
        ) : (
          <img
            src="./assests/noavatar.png"
            alt="No Avatar"
            className="w-[32px] h-[32px] rounded-full"
          />
        )}
      </div>
    ),
  },
  {
    Header: "Total Bookings",
    accessor: "numberOfBooking",
  },
  {
    Header: "Location",
    accessor: "primaryLocation",
  },
];

function Users() {
  const { data: Users, loading, error } = useFetch(fetchUsers);
  const csvData = Users || [];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Layout>
      <div className="bg-customBlue overflow-hidden w-full max-w-[1200px] h-[850px] mt-8 p-6 flex flex-col space-y-6 mr-20 ml-14 rounded-lg shadow-lg">
        <div className="flex items-center gap-20">
          <h1
            className="text-2xl font-semibold text-white"
            style={{ fontFamily: "Poppins" }}
          >
            Users
          </h1>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for..."
                className="p-2 pl-10 rounded-md border bg-customColor w-[260.75px] h-[46px] text-white"
                style={{ borderRadius: "4px", border: "0.6px solid  #343B4F" }}
              />
              <img
                src="./assests/Search.png"
                alt="Search Icon"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
            </div>
          </div>
          <div
            className="flex space-x-1"
            style={{ position: "relative", left: "505px" }}
          >
            <CSVLink
              data={csvData}
              headers={columns.map((col) => ({
                label: col.Header,
                key: col.accessor,
              }))}
              filename={"artists.csv"}
              className="text-white p-2 w-[100px] h-[43.88px] rounded-sm hover: transform transition-transform duration-200 hover:scale-105"
              style={{
                border: "1px solid #ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex flex-row gap-3">
                <span
                  className="font-poppins"
                  style={{ fontWeight: "500", lineHeight: "12.78px" }}
                >
                  Export
                </span>
                <img
                  className="transform rotate-90 w-[11.36px] h-[11.36px]"
                  src="./assests/Arrow Right.png"
                  alt="pic"
                />
              </div>
            </CSVLink>
          </div>
        </div>
        <div className="overflow-auto">
          <DynamicTable 
            columns={columns} 
            data={Users} 
            loading={loading}
            error={error}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Users;
