import React, { useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";

const ReviewFeature = ({
  data = [],
  onDelete,
  onUpdate,
  onSort,
  handleReviewClick,
  totalRecords,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
  isLoading,
}) => {
  const navigate = useNavigate();
  const savedPageSize = localStorage.getItem("pageSize");
  const defaultPageSize = 6;
  const initialPageSize = savedPageSize ? parseInt(savedPageSize) : defaultPageSize;

  const columns = React.useMemo(
    () => [
      {
        Header: "Photo",
        accessor: "photo",
        Cell: ({ value }) => (
          <img
            src={value ? value : ""}
            alt="Artist"
            className="w-12 h-12 rounded-full mr-2 ml-3"
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Artist Name",
        accessor: "artistName",
        sortType: "alphanumeric",
      },
      {
        Header: "Popularity",
        accessor: "popularity",
        sortType: "basic",
        disableSortBy: true,
      },
      {
        Header: "Genre",
        accessor: "genre",
        Cell: ({ value }) => {
          if (!value) return null;

          const genresArray =
            typeof value === "string" ? value.split(",") : value;

          const visibleGenres = genresArray.slice(0, 2);
          const hiddenGenres = genresArray.slice(2);

          return (
            <>
              {visibleGenres.join(", ")}
              {hiddenGenres.length > 0 && (
                <span title={hiddenGenres.join(", ")}>...</span>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Followers",
        accessor: "followers",
        sortType: "basic",
      },
      {
        Header: "Age",
        accessor: "age",
        disableSortBy: true,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex justify-center">
            <i
              className="fas fa-edit text-white p-2 mx-1 cursor-pointer"
              onClick={() => onUpdate(row.original)}
              title="Edit Artist"
            ></i>
            <i
              className="fas fa-trash text-white p-2 mx-1 cursor-pointer"
              onClick={() => onDelete(row.original)}
              title="Delete Artist"
            ></i>
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Reviews",
        accessor: "reviews",
        Cell: ({ row, value }) => (
          <div className="flex items-center gap-2 justify-end cursor-pointer">
            <img
              src="./assests/Views Icon.png"
              alt="ID Icon"
              className="w-8 h-8"
              onClick={() => handleReviewClick(row.original.artistId)}
              title="View Reviews"
              style={{
                borderRadius: "50%",
                padding: "5px",
                transition: "background-color 0.3s ease-in-out",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(128, 128, 128, 0.5)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            />
            <span className="ml-12 text-center">{value}</span>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [onDelete, onUpdate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    state: { pageIndex, pageSize: tablePageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { 
        pageIndex: currentPage,
        pageSize: initialPageSize
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalRecords / pageSize),
      disableSortRemove: true,
    },
    useSortBy,
    usePagination
  );

  const prevSortBy = useRef(sortBy);

  useEffect(() => {
    if (
      prevSortBy.current.length !== sortBy.length ||
      (prevSortBy.current.length > 0 &&
        (prevSortBy.current[0].id !== sortBy[0].id ||
          prevSortBy.current[0].desc !== sortBy[0].desc))
    ) {
      if (sortBy.length) {
        const { id, desc } = sortBy[0];
        onSort(id, desc ? "desc" : "asc");
      }
      prevSortBy.current = sortBy;
    }
  }, [sortBy, onSort]);

  useEffect(() => {
    localStorage.setItem("pageSize", pageSize);
  }, [pageSize]);

  // Handle page size changes
  const handlePageSizeChange = (newSize) => {
    const size = Number(newSize);
    setTablePageSize(size);
    localStorage.setItem("pageSize", size);
    onPageSizeChange(size);
  };

  // Handle pagination
  const handlePageChange = (action) => {
    switch(action) {
      case 'next':
        onPageChange(currentPage + 1);
        break;
      case 'previous':
        onPageChange(currentPage - 1);
        break;
      default:
        onPageChange(action);
    }
  };

  return (
    <div className="pagination-container mx-auto">
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full max-w-full sm:max-w-[1200px] border-collapse mt-15 overflow-hidden border-customBlue mx-auto rounded-lg shadow ml-8 sm:ml-0"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-customColor"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="p-2 text-white font-poppins text-center align-middle"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      height: "60px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="flex items-center justify-center">
                      {column.render("Header")}
                      {column.canSort && (
                        <span className="ml-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fas fa-sort-down"></i>
                            ) : (
                              <i className="fas fa-sort-up"></i>
                            )
                          ) : (
                            <i className="fas fa-sort"></i>
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  className={i % 2 === 0 ? "bg-customBlue" : "bg-customColor"}
                  style={{ height: "63px" }}
                >
                  {row.cells.map((cell, j) => (
                    <td
                      key={j}
                      className="text-white text-center font-poppins p-2 align-middle overflow-hidden overflow-ellipsis whitespace-nowrap"
                      title={cell.value}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination text-white flex flex-col sm:flex-row justify-between items-center p-2 mt-2 bg-customBlue rounded-lg shadow-md mx-auto">
        <span className="text-center sm:text-left">
          {currentPage * pageSize + 1} - {Math.min((currentPage + 1) * pageSize, totalRecords)} of {totalRecords}
        </span>
        <div className="flex items-center mt-2 sm:mt-0">
          <div className="flex items-center mr-4">
            <span className="text-customBorder font-poppins font-medium">
              Rows per page:
            </span>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(e.target.value)}
              className="text-center rounded-md text-customBorder p-2 font-poppins w-[60px] h-[33px] bg-customColor"
            >
              {[6, 10, 20, 30, 40].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <button
              className="pagination-button text-white p-2 mx-1 rounded-md w-[26px] h-[26px] bg-customColor border-customBorder"
              onClick={() => handlePageChange('previous')}
              disabled={currentPage === 0}
            >
              <img
                className="transform -rotate-180"
                src="./assests/Arrow Right.png"
                alt="previous"
              />
            </button>
            <button
              className="pagination-button text-white rounded-md p-2 mx-1 w-[26px] h-[26px] bg-customColor border-customBorder"
              onClick={() => handlePageChange('next')}
              disabled={currentPage >= Math.ceil(totalRecords / pageSize) - 1}
            >
              <img src="./assests/Arrow Right.png" alt="next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewFeature;
