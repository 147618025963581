import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";

function DynamicTable({ columns, data, loading, error }) {
  const tableData = React.useMemo(() => data || [], [data]);
  const tableColumns = React.useMemo(() => columns || [], [columns]);

  const getDefaultPageSize = () => {
    const savedPageSize = localStorage.getItem("defaultPageSize");
    return savedPageSize ? Number(savedPageSize) : 6;
  };

  const [defaultPageSize, setDefaultPageSize] =
    React.useState(getDefaultPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    usePagination
  );

  useEffect(() => {
    localStorage.setItem("defaultPageSize", pageSize);
  }, [pageSize]);

  const renderTableContent = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={columns.length} className="text-center py-4">
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
            </div>
          </td>
        </tr>
      );
    }

    if (error) {
      return (
        <tr>
          <td colSpan={columns.length} className="text-center py-4 text-white">
            Error loading data
          </td>
        </tr>
      );
    }

    if (!data || data.length === 0) {
      return (
        <tr>
          <td colSpan={columns.length} className="text-center py-4 text-white">
            No data available
          </td>
        </tr>
      );
    }

    return page.map((row) => {
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps()}
          className={row.index % 2 === 0 ? "bg-customBlue" : "bg-customColor"}
          style={{ height: "60px" }}
        >
          {row.cells.map((cell) => (
            <td
              {...cell.getCellProps()}
              className="text-white text-center font-poppins p-2 align-middle overflow-hidden overflow-ellipsis whitespace-nowrap"
              title={cell.value}
            >
              {cell.render("Cell")}
            </td>
          ))}
        </tr>
      );
    });
  };

  return (
    <div className="pagination-container mx-auto">
      <table
        {...getTableProps()}
        className="w-full max-w-[1200px] border-collapse mt-15 border-customBlue mx-auto rounded-lg shadow overflow-hidden lg:w-[1100px] xl:w-full 2xl:w-[full]"
        style={{ tableLayout: "fixed" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="bg-customColor">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="p-2 text-white font-poppins text-center align-middle"
                  style={{
                    fontSize: "16px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    height: "60px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="">
          {renderTableContent()}
        </tbody>
      </table>
      {(!loading && data && data.length > 0) && (
        <div className="pagination text-white flex flex-col sm:flex-row justify-between items-center p-2 mt-2 bg-customBlue rounded-lg shadow-md mx-auto">
          <span className="text-center sm:text-left">
            {pageIndex * pageSize + 1} -{" "}
            {Math.min((pageIndex + 1) * pageSize, data.length)} of {data.length}
          </span>
          <div className="flex items-center mt-2 sm:mt-0">
            <div className="flex items-center mr-4">
              <span className="mr-2 text-customBorder font-poppins font-medium">
                Rows per page:
              </span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="text-center rounded-md text-customBorder p-2 font-poppins w-[60px] h-[33px] bg-customColor"
              >
                {[6, 10, 20, 30, 40].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                className="pagination-button text-white p-2 mx-1 rounded-md w-[26px] h-[26px] bg-customColor border-customBorder"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img
                  className="transform -rotate-180"
                  src="./assests/Arrow Right.png"
                  alt="previous"
                />
              </button>
              <button
                className="pagination-button text-white rounded-md p-2 mx-1 w-[26px] h-[26px] bg-customColor border-customBorder"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <img src="./assests/Arrow Right.png" alt="next" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DynamicTable;
